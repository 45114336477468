body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Eurostile Regular';
  src: url(/static/media/ui.c955393d.woff2) format('woff2'),
        url(/static/media/ui.e1449c3b.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

a.active button {
  background-color: #FF6600;
  color: #000 !important;
}

button {
  font-family: 'Eurostile Regular';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px !important;
  transition: 175ms all ease-in-out;
}

a:not(.active) button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

h1 {
  text-transform: uppercase;
  font-size: 21px;
}

* {
  letter-spacing: 0.75px;
}

li a:link,
li a:active,
li a:visited {
  text-decoration: none;
  color: #FF6600;
  font-weight: 600;
}

.topKiller header {
  white-space: pre;
}

.shipLoss h3,
.shipLoss ul > li:before {
  color: red !important;
}

.shipLoss div {
  border-color: red !important;
}
