@font-face {
  font-family: 'Eurostile Regular';
  src: url('./assets/ui.woff2') format('woff2'),
        url('./assets/ui.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

a.active button {
  background-color: #FF6600;
  color: #000 !important;
}

button {
  font-family: 'Eurostile Regular';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px !important;
  transition: 175ms all ease-in-out;
}

a:not(.active) button:hover {
  filter: brightness(140%);
}

h1 {
  text-transform: uppercase;
  font-size: 21px;
}

* {
  letter-spacing: 0.75px;
}

li a:link,
li a:active,
li a:visited {
  text-decoration: none;
  color: #FF6600;
  font-weight: 600;
}

.topKiller header {
  white-space: pre;
}

.shipLoss h3,
.shipLoss ul > li:before {
  color: red !important;
}

.shipLoss div {
  border-color: red !important;
}